/*
:root {
    --model-county-color: rgb(107,174,214);
    --model-state-color: rgb(251,106,74);
    --observed-county-color: rgb(8,48,107);
    --observed-state-color: rgb(103,0,13);
    --line-fill: none;
    --line-stroke-width : 2px;
    --scatter-fill: white;
    --scatter-stroke-width: 2px;
}

.model.county {
    fill: var(--line-fill);
    stroke: var(--model-county-color);
    stroke-width: var(--line-stroke-width);
}

.model.state {
    fill: var(--line-fill);
    stroke: var(--model-state-color);
    stroke-width: var(--line-stroke-width);
}

.observed.county {
    fill: var(--line-fill);
    stroke: var(--observed-county-color);
    stroke-width: var(--line-stroke-width);
}

.observed.state {
    fill: var(--line-fill);
    stroke: var(--observed-state-color);
    stroke-width: var(--line-stroke-width);
}

.model.interactive {
    fill: var(--line-fill);
    stroke: red;
    stroke-width: var(--line-stroke-width);
}

.scatter.county {
    fill: var(--scatter-fill);
    stroke-width: var(--scatter-stroke-width);
    stroke: var(--observed-county-color);
}

.scatter.state {
    fill: var(--scatter-fill);
    stroke-width: var(--scatter-stroke-width);
    stroke: var(--observed-state-color);
}
*/

.title {
    font-family: sans-serif;
    text-align: center;
}

.timestep {
    stroke-width: 2px;
    stroke: black;
    fill: none;
}

.zoom {
    cursor: move;
    fill: none;
    pointer-events: all;
}

.timechart-svg {
    width: 100%;
    height: 90%;
}