.rectangle {
  display: flex;
  width: 60px;
  height: 50px;
  background-color: #d8b365;
}

.circle {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: green;
  border-radius: 50%;
}